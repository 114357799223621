// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-merch-page-js": () => import("./../../../src/templates/merch-page.js" /* webpackChunkName: "component---src-templates-merch-page-js" */),
  "component---src-templates-music-page-js": () => import("./../../../src/templates/music-page.js" /* webpackChunkName: "component---src-templates-music-page-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-tour-page-js": () => import("./../../../src/templates/tour-page.js" /* webpackChunkName: "component---src-templates-tour-page-js" */),
  "component---src-templates-video-page-js": () => import("./../../../src/templates/video-page.js" /* webpackChunkName: "component---src-templates-video-page-js" */)
}

